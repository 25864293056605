/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';

// import useLangRedirect from '../hooks/useLangRedirect';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Row from '../components/Row';
import Col from '../components/Col';
import Button from '../components/Button';
import GridItem from '../components/GridItem';

import { breakpoint } from '../theme';
import { SectionHeader, SectionFooter } from '../utils/GlobalFormatting';

const Container = styled.div`
  /* max-width: ${(props) => props.theme.grid.maxWidthText}; */
  margin: 0 auto;
`;

const Section = styled.div`
  text-align: center;
  justify-content: center;
  margin-bottom: ${rem(50)};
  padding-top: ${rem(50)};
`;

const Teams = styled(Row)`
  justify-content: center;
`;

const TeamsItem = styled(Col)`
  text-align: center;
  width: 48%;
  margin-bottom: ${(props) => props.theme.spacing.gutter};

  ${breakpoint('medium')} {
    width: 29%;
  }

  ${breakpoint('mediumlarge')} {
    width: 20%;
  }

  ${breakpoint('large')} {
    width: 17%;
  }

  ${breakpoint('xlarge')} {
    width: 14%;
  }
`;

// const PageTitle = styled.h1`
//   line-height: 1.2em;
//   font-size: ${rem(20)};

//   ${breakpoint('large')} {
//     font-size: ${rem(26)};
//   }
// `;

function Sport({ data }) {
  const { t } = useTranslation();
  const { sportsJson: sport, allTeamsJson: teams } = data;

  // useLangRedirect(team);

  if (!sport) {
    return null;
  }

  return (
    <Layout>
      <SEO title={sport.title} />

      <Hero
        heading={sport.title}
        // image={featuredMedia?.localFile}
        pageTitle
        preHeading={t('sport')}
        // actions="Actions"
        tint
      />

      <Container>
        <Section>
          <SectionHeader as={Row}>
            <Col>
              <h3>{t('selectTeam')}</h3>
              <p>{t('teamSectionDesc')}</p>
            </Col>
          </SectionHeader>
          <Teams>
            {teams.edges.map(({ node: team }) => (
              <TeamsItem key={team.id}>
                <GridItem
                  link={`/${team.slug}/`}
                  image={team.logo}
                  title={team.title}
                />
              </TeamsItem>
            ))}
          </Teams>
          <SectionFooter as={Row}>
            <Col>
              <h4>{t('teamSectionFooterTitle')}</h4>
              <p>{t('teamSectionFooterDesc')}</p>
              <Button className="small" link="/kontakt/">
                {t('teamSectionFooterCta')}
              </Button>
            </Col>
          </SectionFooter>
        </Section>
      </Container>
    </Layout>
  );
}

Sport.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query($slug: String!) {
    sportsJson(slug: { eq: $slug }) {
      id
      title
      slug
    }
    allTeamsJson(filter: { sport: { eq: $slug } }) {
      edges {
        node {
          id
          sport
          league
          slug
          title
          body
          logo {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`;

export default Sport;
